import { render, staticRenderFns } from "./AtomLocaleSwitch.vue?vue&type=template&id=ab2b012c&scoped=true&"
import script from "./AtomLocaleSwitch.vue?vue&type=script&lang=js&"
export * from "./AtomLocaleSwitch.vue?vue&type=script&lang=js&"
import style0 from "./AtomLocaleSwitch.vue?vue&type=style&index=0&id=ab2b012c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab2b012c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomIcon: require('/app/components/atoms/common/AtomIcon.vue').default})
