
import baseConstants from '../../store/base/-constants'
import requestOfferConstants from '~/store/requestOffer/-constants'
import { smoothScrollToTargetId } from '~/helpers/util'
export default {
  name: 'MoleculeAvailableSpacesTriggerOnMobile',
  props: {
    sections: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    }
  },
  computed: {
  },
  methods: {
    showAvailableSpaces() {
      this.scrollTo3DSection()
    },
    scrollTo3DSection() {
      const buildingSection = this.sections.find((section) => section.type === 'Building') || {}
      if (buildingSection) {
        smoothScrollToTargetId(`#${buildingSection.hashAnchor}`)
      }
    }
  }
}
